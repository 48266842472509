@media screen and (min-width: 976px) {
  .about-section {
    background: radial-gradient(
      ellipse at 50% 50%,
      rgba(161, 198, 239, 0.48) 0%,
      #fff 97%
    );
  }
}

@media screen and (max-width: 976px) {
  .about {
    background: url("../../assets/images/women.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    height: inherit;
  }

  .frosted-effect {
    background: rgba(51, 83, 118, 0.716) 0%;
    background-position: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    content: "";
    /* content-visibility: visible; */
    /* margin-top: 12vh; */
    height: 80vh;
    width: 100vw;
  }
}
