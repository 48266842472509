@media screen and (max-width: 976px) {
  .home {
    background: url("../../assets/images/happy-family.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    height: inherit;
  }

  .frosted-effect-home {
    background: rgba(5, 3, 3, 0.488);
    background-position: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    content: "";
    content-visibility: visible;
    margin-top: 12vh;
    width: 100vw;
  }
}
